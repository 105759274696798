<template>
  <Top />
  <div class="container">
    <Transaction-navbar :title="lang.v.back" />
    <div class="main">
      <h3>
        {{ route.query?.edit ? lang.v.Edit : lang.v.Create
        }}{{ buyOrSell ? lang.v.Bill : lang.v.SellBill }}
      </h3>
      <p class="tips">{{ lang.v.NumberPending }} ∞</p>
      <div class="payment">
        <div class="payment-bottom">
          <div class="change-way">
            <input
              :disabled="route.query?.edit"
              type="number"
              class="input"
              pattern="^\d*(\.\d{1,2})?$"
              :placeholder="
                buyOrSell ? lang.v.enterPpurchaseQuantity : lang.v.enterSale
              "
              v-model="form.num"
            />
            <p>{{ lang.v.pointsBalance }} {{ userinfo?.balance }}</p>
          </div>
          <div class="change-max">
            <p>{{ lang.v.setMaxUantity }}</p>
            <p>{{ max_config }}</p>
          </div>
        </div>
      </div>
      <div class="payment">
        <div class="payment-bottom">
          <div class="change-unit">
            <span>{{ lang.v.unit }}</span>
            <input
              :disabled="route.query?.edit"
              type="number"
              class="input"
              pattern="^\d*(\.\d{1,2})?$"
              :placeholder="
                buyOrSell ? lang.v.setPurchasePrice : lang.v.setSalePrice
              "
              v-model="form.price"
            />
          </div>
          <div class="change-max">
            <p>{{ lang.v.rangePrice }}</p>
            <p>
              {{ lang.v.unit + price.min + ' ~ ' + lang.v.unit + price.max }}
            </p>
          </div>
        </div>
      </div>
      <Payment :create="route.query?.type == 1" :type="route.query?.type" />
      <div class="desc">
        <p>
          <el-icon><WarningFilled /></el-icon> &nbsp;${{
            lang.v.tradingPrecautions
          }}
        </p>
        <p>{{ lang.v.afterQuantity }}</p>
        <p>{{ lang.v.confirmCancelled }}</p>
      </div>
    </div>
  </div>
  <div class="submit">
    <button :class="[btn ? 'submit-btn' : 'def-btn']" @click="onSubmit">
      {{ lang.v.confirmPeddingOrder }}
    </button>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router';
import { lang } from '@/lang';
import { WarningFilled } from '@element-plus/icons-vue';
import { onMounted, reactive, ref, watch } from 'vue';
import { showFailToast, showSuccessToast } from 'vant';
import {
  fetchTransactionServeRemnantCount,
  fetchTransactionServePublish,
  updataPeddingOrder,
  fetchTransactionServeConfig,
  fetchTransactionServeGoods,
} from '@/api/fetcher';
import TransactionNavbar from '@/components/TransactionNavbar/index.vue';
import Payment from '@/components/Payment/index.vue';
import Top from '@/components/Top/top.vue';
import dataCenter from '@/dataCenter';

const userinfo = ref(JSON.parse(localStorage.getItem('userInfo')));
const route = useRoute();
const buyOrSell = route.query?.type == 1;

const btn = ref(false);
// 参数
const form = reactive({
  num: localStorage.getItem('Pendingform')
    ? JSON.parse(localStorage.getItem('Pendingform') || '')?.num
    : '',
  price: localStorage.getItem('Pendingform')
    ? JSON.parse(localStorage.getItem('Pendingform') || '')?.price
    : '',
});
const price = reactive({
  min: 0,
  max: 0,
});
//最大数量
const max_config = ref(0);
// 请求次数
const getCurrent = async () => {
  const data = await fetchTransactionServeRemnantCount({
    userId: userinfo.value.userId,
  });
};

// 监听数据
watch(form, () => {
  localStorage.setItem('Pendingform', JSON.stringify(form));
  if (form.num && form.price) {
    btn.value = true;
  } else {
    btn.value = false;
  }
});
//请求最大数量和单价范围
const getMaxAndPrice = async () => {
  const unit_price = await fetchTransactionServeConfig({
    name: 'c2c_integral_unit_price',
  });
  const price_range = unit_price;
  const maxIntegral = await fetchTransactionServeConfig({
    name: 'c2c_max_integral',
  });
  max_config.value = maxIntegral;
  const parts = price_range.split('-');
  if (parts.length !== 2) {
    return;
  }
  price.min = parseInt(parts[0]);
  price.max = parseInt(parts[1]);
};

// 挂载请求
onMounted(() => {
  getMaxAndPrice();
  if (form.num && form.price) {
    btn.value = true;
  } else {
    btn.value = false;
  }
  if (route.query?.edit) {
    getTransactionGoods();
  }
});
//获取挂单信息
const getTransactionGoods = async () => {
  const data = await fetchTransactionServeGoods({
    id: route.query.id,
  });
  if (data) {
    form.num = data?.integral;
    form.price = data?.amount;
  }
};
//提交
const onSubmit = async () => {
  if (!btn.value) return;
  if (route.query?.type == 1) {
    //买单
    if (form.num > max_config.value) {
      showFailToast(lang.v.caresure);
      return;
    }
    if (
      form.price / form.num < price.min ||
      form.price / form.num > price.max
    ) {
      showFailToast(lang.v.pointsRange);
      return;
    }
    try {
      if (route.query?.edit) {
        await updataPeddingOrder({
          id: route.query?.id,
          type: route.query?.type,
          version: route.query?.version,
        });
      } else {
        await fetchTransactionServePublish({
          type: route.query?.type,
          integral: form.num,
          amount: form.price,
        });
      }
    } catch {
      return;
    }
    showSuccessToast(lang.v.orderSuccessfully);
    localStorage.setItem('Pendingform', '');
    setTimeout(() => {
      window.history.back();
    }, 1000);
  } else {
    const existing = dataCenter.payStatus.data.find(
      item => item.id == route.query.id
    );
    //卖单
    if (form.num > max_config.value) {
      showFailToast(lang.v.caresure);
      return;
    }
    if (
      form.price / form.num < price.min ||
      form.price / form.num > price.max
    ) {
      showFailToast(lang.v.pointsRange);
      return;
    }
    try {
      if (route.query?.edit) {
        await updataPeddingOrder({
          id: route.query?.id,
          type: route.query?.type,
          version: route.query?.version,
          payWay: existing?.payWay,
          payDesc: existing?.payDesc,
        });
      } else {
        await fetchTransactionServePublish({
          type: route.query?.type,
          amount: form.num,
          integral: form.price,
          payWay: existing?.payWay,
          payDesc: existing?.payDesc,
        });
      }
    } catch {
      return;
    }
    showSuccessToast(lang.v.orderSuccessfully);
    localStorage.setItem('Pendingform', '');
    setTimeout(() => {
      window.history.back();
    }, 1000);
  }
};
</script>

<style lang="less" scoped>
.container {
  padding: 0.14rem;

  .main {
    margin-top: 0.14rem;
    h3 {
      font-size: 0.18rem;
      color: #222;
      font-weight: 600;
      margin-bottom: 0.08rem;
    }
    .tips {
      font-size: 0.12rem;
      color: #858c9f;
    }
  }
}

.payment {
  margin-top: 0.1rem;
  padding: 0.12rem;
  background: #ffffff;
  border-radius: 0.08rem;
  .payment-bottom {
    margin-top: 0.12rem;
    .text-tips {
      font-weight: 400;
      font-size: 0.14rem;
      color: #222222;
      line-height: 0.22rem;
    }
    .change-way {
      background: #f6f6f8;
      border-radius: 0.06rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 0.12rem;
      padding: 0.12rem;
      color: #858c9f;
      .input {
        font-size: 0.14rem;
        background: transparent;
        border: 0;
      }
    }
    .change-unit {
      background: #f6f6f8;
      border-radius: 0.06rem;
      display: flex;
      align-items: center;
      font-size: 0.12rem;
      padding: 0.12rem;

      .input {
        color: #858c9f;
        margin-left: 0.05rem;
        font-size: 0.14rem;
        background: transparent;
        border: 0;
      }
    }
    .change-max {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.12rem 0.12rem 0;
      font-size: 0.12rem;
      color: #858c9f;
    }
  }
}
.desc {
  margin-top: 0.2rem;
  p {
    font-size: 0.12rem;
    line-height: 0.18rem;
    color: #858c9f;
    margin-bottom: 0.1rem;
    display: flex;
    align-items: center;
  }
}
.submit {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 0.6rem;
  background: #ffffff;
  box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.03);
  display: flex;
  align-items: center;
  justify-content: center;
  .submit-btn {
    margin: 0.07rem 0.14rem;
    width: 100%;
    height: 0.46rem;
    background: #ed931e;
    border-radius: 0.23rem;
    font-weight: 600;
    font-size: 0.16rem;
    color: #ffffff;
    line-height: 0.22rem;
  }
  .def-btn {
    margin: 0.07rem 0.14rem;
    width: 100%;
    height: 0.46rem;
    background: #aaaaaa;
    border-radius: 0.23rem;
    font-weight: 600;
    font-size: 0.16rem;
    color: #ffffff;
    line-height: 0.22rem;
  }
}
</style>
